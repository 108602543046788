












import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class SavedSuccessfullyModal extends Vue {
  @Prop()
  props: any;

  created() {
    setTimeout(() => {
      this.$emit("close-modal");
    }, 1200);
  }

  close() {
    this.$emit("close-modal");
  }
}
